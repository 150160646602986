import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Container, Row } from "react-bootstrap"
import Img from "gatsby-image"
import styles from "./cards.module.scss"
import { slugify } from "../utils/slugify.js"

const Cards = ({ nodes }) => {
  return (
    <Container fluid className={`pt-5 pl-0 pr-0`}>
      <Row
        className={`d-flex justify-content-center w-100 m-0 ${styles.cardItemContainer}`}
      >
        {nodes.map((item, i) => {
          return (
            <React.Fragment key={i}>
              <Link
                to={`/${slugify(item.data.Name)}/`}
                className={`mb-5 p-4 d-flex flex-column align-items-center col-sm bg-white ${styles.cardItem}`}
              >
                <h2 className={`mb-1 text-center ${styles.cardItemTitle}`}>
                  {item.data.Name}
                </h2>
                <p className={`mb-1 text-center ${styles.cardItemDescription}`}>
                  {item.data.Description}
                </p>
                <div className={`text-primary ${styles.cardItemLink}`}>
                  {item.data.Link_Text}
                </div>
                <div className={`${styles.imgContainer}`}>
                  <Img
                    fluid={item.data.Icon.localFiles[0].childImageSharp.fluid}
                    className={`mt-2 ${styles.cardItemImg}`}
                  />
                </div>
              </Link>

              {i !== 0 && (i - 2) % 3 === 0 && (
                <div
                  style={{
                    flexBasis: `100%`,
                    height: "0",
                  }}
                />
              )}
            </React.Fragment>
          )
        })}
      </Row>
    </Container>
  )
}

Cards.propTypes = {
  nodes: PropTypes.array,
}

Cards.defaultProps = {
  siteTitle: ``,
}

export default Cards
