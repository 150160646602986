import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cards from "../components/cards"

const Solutions = () => {
  const data = useStaticQuery(graphql`
    query solutionsQueryAndPageTitleQuery {
      header: allAirtable(
        filter: { table: { eq: "Header" } }
        sort: { order: DESC }
      ) {
        nodes {
          data {
            Headline
          }
        }
      }
      solutions: allAirtable(
        filter: { table: { eq: "Solutions" } }
        sort: { fields: data___Sort, order: ASC }
      ) {
        nodes {
          data {
            Name
            Description
            Link_Text
            Detail_Title
            Video_Link
            Detail_Description
            Data_Value
            Data_Sub
            Success_Headline
            Code_Description
            Icon {
              localFiles {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 512) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            Code {
              localFiles {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 512) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
          recordId
        }
      }
    }
  `)
  const {
    header: { nodes: headerNodes },
    solutions: { nodes: solutionsNodes },
  } = data

  return (
    <Layout pageTitle={headerNodes[0].data.Headline} headlineBlue noFooter>
      <SEO title="Solutions" />
      <Cards nodes={solutionsNodes} />
    </Layout>
  )
}

export default Solutions
